jQuery(document).ready(function($) {
	var header = $(".c-header");

	createSticky(header);

	$("#js__c-header__navtoggle").click(function() {
		$(".c-navigation").fadeToggle();
		$(".c-navicon").toggleClass("c-navicon--active");
		$("html").toggleClass("noscroll");
	});

	$(".c-navigation__item-level1").click(function() {
		$(".c-navigation").fadeToggle();
		$(".c-navicon").toggleClass("c-navicon--active");
		$("html").toggleClass("noscroll");
	});

	$(".c-header-animation").fadeOut(3000);

});
